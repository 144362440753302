import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialMenuState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: undefined,
  new_customer_mahasiswa: {
    count: 0,
    count_past: 0,
    rasio: 0
  },
  new_customer_residential: {
    count: 0,
    count_past: 0,
    rasio: 0
  },
  total_fab: {
    count: 0,
    rasio: 0
  },
  waiting_survey: {
    count: 0,
    rasio: 0,
    count_done: 0,
    rasio_done: 0
  },
  waiting_instalasi: {
    count: 0,
    rasio: 0,
    count_done: 0,
    rasio_done: 0
  },
  reject: {
    count: 0,
    rasio: 0
  },
  avg_days_survey: {
    avg_done : 0,
    avg_past_done : 0,
    rasio : 0
  },
  avg_days_instalasi: {
    avg_done : 0,
    avg_past_done : 0,
    rasio : 0
  },
  sales: null,
  teknikal: null,
  target: null,
  all_karyawan: null,
  all_karyawan_sales: null,
  all_karyawan_teknikal: null,
  fab_chart: null,
  survey_chart: null,
  instalasi_chart: null,
  residential_cluster_chart: null,
  residential_cluster_fab_chart: null,
  lastError: null,
  menuForEdit: undefined,
  tgl_mulai: "",
  tgl_selesai: ""
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: initialMenuState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		menuFetched: (state, action) => {
      const { totalCount, entities, tgl_mulai, tgl_selesai } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.tgl_mulai = tgl_mulai ? tgl_mulai : `${moment().format("YYYY-MM")}-01`;
      state.tgl_selesai = tgl_selesai ? tgl_selesai : moment().format("YYYY-MM-DD");
    },
		
    menuNewCustomerMahasiswa: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.new_customer_mahasiswa = entities;
    },
    
    menuNewCustomerResidential: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.new_customer_residential = entities;
    },
    
    menuTotalFab: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.total_fab = entities;
    },
    
    menuWaitingSurvey: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.waiting_survey = entities;
    },
    
    menuWaitingInstalasi: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.waiting_instalasi = entities;
    },
    
    menuReject: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.reject = entities;
    },

    menuReset: (state) => {
      state.tgl_mulai = null;
      state.tgl_selesai = null;
    },
    
    menuSet: (state, action) => {
      const { tgl_mulai, tgl_selesai } = action.payload;
      state.tgl_mulai = tgl_mulai;
      state.tgl_selesai = tgl_selesai;
    },
  },
});
