import { createSlice } from "@reduxjs/toolkit";

const initialResidentialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  meta: {total : 0},
  links: null,
  residentialForEdit: undefined,
  residentialForExport : []
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const residentialSlice = createSlice({
  name: "residential",
  initialState: initialResidentialState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		residentialFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		residentialAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
		residentialUniqueFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
		residentialExportFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.residentialForExport = entities;
      state.totalCount = entities.length;
    },
    residentialCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.residential.push(action.payload.residential);
    },
    residentialDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.residential.filter(el => el.residential_id !== action.payload.id);
    },
    getResidential: (state, action) => {
      state.actionsLoading = false;
      state.residentialForEdit = action.payload.residentialForEdit;
      state.error = null;
    },
    getExportResidential: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.residentialForExport === undefined) {
        state.residentialForExport = [];
      }else{
        state.residentialForExport.push(action.payload.residentialForExport);
      }
         
      state.error = null;
    },
    // updateProduct
    residentialUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.residential = state.entities.residential.map(entity => {
        if (entity.residential_id === action.payload.residential.residential_id) {
          return action.payload.residential;
        }
        return entity;
      });
    },
  },
});
