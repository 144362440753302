import { createSlice } from "@reduxjs/toolkit";

const initialExportcrmState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  request: undefined,
  percentageRequest: 0,
  survey: undefined,
  percentageSurvey: 0,
  instalasi: undefined,
  percentageInstalasi: 0,
  progress: undefined,
  percentageProgress: 0,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const exportcrmSlice = createSlice({
  name: "exportcrm",
  initialState: initialExportcrmState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		exportcrmFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
		exportcrmFetchedRequest: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.request = entities;
      state.totalCount = totalCount;
    },
		exportcrmFetchedSurvey: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.survey = entities;
      state.totalCount = totalCount;
    },
		exportcrmFetchedInstalasi: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.instalasi = entities;
      state.totalCount = totalCount;
    },
		exportcrmFetchedProgress: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.progress = entities;
      state.totalCount = totalCount;
    },
  },
});
