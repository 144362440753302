import axios from 'axios'
import { apiv3Url } from "../../../../_metronic/_helpers";

//export const URL = `${apiUrl}:8888/user-service/user`
export const URLV3 = `${apiv3Url}/api/dashboard`;

export const getNewCustomerMahasiswa = (tgl_mulai, tgl_selesai) => {
    return axios.get(`${URLV3}/new-customer-student?tgl_mulai=${tgl_mulai}&tgl_selesai=${tgl_selesai}`)
}

export const getNewCustomerResidential = (tgl_mulai, tgl_selesai) => {
    return axios.get(`${URLV3}/new-customer-residential?tgl_mulai=${tgl_mulai}&tgl_selesai=${tgl_selesai}`)
}

export const getTotalFab = (tgl_mulai, tgl_selesai) => {
    return axios.get(`${URLV3}/total-fab?tgl_mulai=${tgl_mulai}&tgl_selesai=${tgl_selesai}`)
}

export const getWaitingSurvey = (tgl_mulai, tgl_selesai) => {
    return axios.get(`${URLV3}/total-waiting-survey?tgl_mulai=${tgl_mulai}&tgl_selesai=${tgl_selesai}`)
}

export const getWaitingInstalasi = (tgl_mulai, tgl_selesai) => {
    return axios.get(`${URLV3}/total-waiting-instalasi?tgl_mulai=${tgl_mulai}&tgl_selesai=${tgl_selesai}`)
}

export const getReject = (tgl_mulai, tgl_selesai) => {
    return axios.get(`${URLV3}/total-reject?tgl_mulai=${tgl_mulai}&tgl_selesai=${tgl_selesai}`)
}