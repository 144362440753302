import { createSlice } from "@reduxjs/toolkit";

const initialRequestState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  meta: {total : 0},
  links: null,
  requestForEdit: undefined,
  requestForExport: [],
  surveyForExport: [],
  instalasiForExport: [],
  progressForExport: [],
  rejectForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const requestSlice = createSlice({
  name: "request",
  initialState: initialRequestState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		requestFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		requestExportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.requestForExport = entities;
      state.totalCount = totalCount;
    },
    requestCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.request.push(action.payload.request);
    },
    requestDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.request.filter(el => el.request_id !== action.payload.id);
    },
    getRequest: (state, action) => {
      state.actionsLoading = false;
      state.requestForEdit = action.payload.requestForEdit;
      state.error = null;
    },
    getExportRequest: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.requestForExport === undefined) {
        state.requestForExport = [];
      }else{
        state.requestForExport.push(action.payload.requestForExport);
      }
      state.error = null;
    },
    getExportSurvey: (state, action) => {
      state.actionsLoading = false;
      const data = action.payload.surveyForExport;
      if(data === undefined) {
        state.surveyForExport = [];
      }else{
        state.surveyForExport.push(data);
      }
      state.error = null;
    },
    getExportInstalasi: (state, action) => {
      state.actionsLoading = false;
      const data = action.payload.instalasiForExport;
      if(data === undefined) {
        state.instalasiForExport = [];
      }else{
        state.instalasiForExport.push(data);
      }
      state.error = null;
    },
    getExportProgress: (state, action) => {
      state.actionsLoading = false;
      const data = action.payload.progressForExport;
      if(data === undefined) {
        state.progressForExport = [];
      }else{
        state.progressForExport.push(data);
      }
      state.error = null;
    },
    getExportReject: (state, action) => {
      state.actionsLoading = false;
      const data = action.payload.rejectForExport;
      if(data === undefined) {
        state.rejectForExport = [];
      }else{
        state.rejectForExport.push(data);
      }
      state.error = null;
    },
    // updateProduct
    requestUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.request = state.entities.request.map(entity => {
        if (entity.request_id === action.payload.request.request_id) {
          return action.payload.request;
        }
        return entity;
      });
    },
  },
});
