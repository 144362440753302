import { createSlice } from "@reduxjs/toolkit";

const initialSurveyState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  meta: {total : 0},
  links: null,
  surveyForEdit: undefined,
  surveyForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const surveySlice = createSlice({
  name: "survey",
  initialState: initialSurveyState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		surveyFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		surveyExportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.surveyForExport = entities;
      state.totalCount = totalCount;
    },
    surveyCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.survey.push(action.payload.survey);
    },
    surveyDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.survey.filter(el => el.survey_id !== action.payload.id);
    },
    getSurvey: (state, action) => {
      state.actionsLoading = false;
      state.surveyForEdit = action.payload.surveyForEdit;
      state.error = null;
    },
    getExportSurvey: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.surveyForExport === undefined) {
        state.surveyForExport = [];
      }else{
        state.surveyForExport.push(action.payload.surveyForExport);
      }
      state.error = null;
    },
    // updateProduct
    surveyUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.survey = state.entities.survey.map(entity => {
        if (entity.survey_id === action.payload.survey.survey_id) {
          return action.payload.survey;
        }
        return entity;
      });
    },
  },
});
