import { createSlice } from "@reduxjs/toolkit";

const initialProgressState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  progressForEdit: undefined,
  progressForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const progressSlice = createSlice({
  name: "progress",
  initialState: initialProgressState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		progressFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
		progressExportFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.progressForExport = entities;
      state.meta = meta;
      state.links = links;
    },
    progressCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.progress.push(action.payload.progress);
    },
    progressDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.progress.filter(el => el.progress_id !== action.payload.id);
    },
    getProgress: (state, action) => {
      state.actionsLoading = false;
      state.progressForEdit = action.payload.progressForEdit;
      state.error = null;
    },
    getExportProgress: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.progressForExport === undefined) {
        state.progressForExport = [];
      }else{
        state.progressForExport.push(action.payload.progressForExport);
      }
      state.error = null;
    },
    // updateProduct
    progressUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.progress = state.entities.progress.map(entity => {
        if (entity.progress_id === action.payload.progress.progress_id) {
          return action.payload.progress;
        }
        return entity;
      });
    },
  },
});
