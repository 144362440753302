import { Modal } from "react-bootstrap";

import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import iconLoading from "./animation_300_google.gif"


const LoadingCustom = () => {
    const { loading } = useSelector(
        (state) => ({
          loading: state.loading.actionsLoading,
        }),
        shallowEqual
      );

  return (
    <Modal
      size="sm"
      show={loading}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <img src={iconLoading} alt="Loading..." style={{width:"100%", height:"100%"}} />
        <h3 className="text-center">Loading...</h3>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingCustom;
