import * as requestFromServer from "./notifikasiCrud";

import { callTypes, notifikasiSlice } from "./notifikasiSlice";

const { actions } = notifikasiSlice;

export const fetchAllNotifikasi = (history,type=null,tgl_fetch=null) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return type === "new" ? requestFromServer
    .getAllNotifikasi(type,tgl_fetch)
    .then((response) => {
      const { data, count } = response.data;
      dispatch(actions.notifikasiFetchedNew({ totalCount : count, entities : data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find role";
      if(history){
        if(error.response.status === 401) {
          history.push("/logout");
        }
      }
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    }) :
    requestFromServer
    .getAllNotifikasi(type,tgl_fetch)
    .then((response) => {
      const { data, count } = response.data;
      dispatch(actions.notifikasiFetched({ totalCount : count, entities : data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find role";
      if(history){
        if(error.response.status === 401) {
          history.push("/logout");
        }
      }
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
