import { createSlice } from "@reduxjs/toolkit";

const initialLogState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  logForEdit: undefined,
  logForExport: []
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const logSlice = createSlice({
  name: "log",
  initialState: initialLogState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		logFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
    logCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.log.push(action.payload.log);
    },
    logDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.log.filter(el => el.log_id !== action.payload.id);
    },
    logDisabled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.log.filter(el => el.log_id !== action.payload.id);
    },
    getLog: (state, action) => {
      state.actionsLoading = false;
      state.logForEdit = action.payload.logForEdit;
      state.error = null;
    },
    getExportLog: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.logForExport === undefined) {
        state.logForExport = [];
      }else{
        state.logForExport.push(action.payload.logForExport);
      }
      state.error = null;
    },
    // updateProduct
    logUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.log = state.entities.log.map(entity => {
        if (entity.log_id === action.payload.log.log_id) {
          return action.payload.log;
        }
        return entity;
      });
    },
  },
});
