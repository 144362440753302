import { createSlice } from "@reduxjs/toolkit";

const initialAnnouncementState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  entitiesTraffic: null,
  exportData: null,
  currency: null,
  lastError: null,
  announcementForEdit: undefined,
  announcementForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const announcementSlice = createSlice({
  name: "announcement",
  initialState: initialAnnouncementState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		announcementFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		exportAnnouncement: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
		exportFetchedAnnouncement: (state, action) => {
      const { totalCount, export_data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = export_data;
      state.totalCount = totalCount;
    },
		trafficAnnouncement: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesTraffic = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
    announcementCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.announcement.push(action.payload.announcement);
    },
    announcementDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.announcement.filter(el => el.announcement_id !== action.payload.id);
    },
    announcementDisabled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.announcement.filter(el => el.announcement_id !== action.payload.id);
    },
    getAnnouncement: (state, action) => {
      state.actionsLoading = false;
      state.announcementForEdit = action.payload.announcementForEdit;
      state.error = null;
    },
    getExportAnnouncement: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.announcementForExport === undefined) {
        state.announcementForExport = [];
      }else{
        state.announcementForExport.push(action.payload.announcementForExport);
      }
      state.error = null;
    },
    // updateProduct
    announcementUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.announcement = state.entities.announcement.map(entity => {
        if (entity.announcement_id === action.payload.announcement.announcement_id) {
          return action.payload.announcement;
        }
        return entity;
      });
    },
  },
});
