import { createSlice } from "@reduxjs/toolkit";
import {
  monthDate
} from "../../../../_metronic/_helpers";

const initialDashboardState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: undefined,
  new_customer_mahasiswa: {
    count: 0,
    count_past: 0,
    rasio: 0
  },
  new_customer_residential: {
    count: 0,
    count_past: 0,
    rasio: 0
  },
  total_fab: {
    count: 0,
    rasio: 0
  },
  waiting_survey: {
    count: 0,
    rasio: 0,
    count_done: 0,
    rasio_done: 0
  },
  waiting_instalasi: {
    count: 0,
    rasio: 0,
    count_done: 0,
    rasio_done: 0
  },
  reject: {
    count: 0,
    rasio: 0
  },
  avg_days_survey: {
    avg_done : 0,
    avg_past_done : 0,
    rasio : 0
  },
  avg_days_instalasi: {
    avg_done : 0,
    avg_past_done : 0,
    rasio : 0
  },
  sales: null,
  teknikal: null,
  target: null,
  all_karyawan: null,
  all_karyawan_sales: null,
  all_karyawan_teknikal: null,
  fab_chart: null,
  survey_chart: null,
  instalasi_chart: null,
  residential_cluster_chart: null,
  residential_cluster_fab_chart: null,
  lastError: null,
  dashboardForEdit: undefined,
  tgl_mulai: null,
  tgl_selesai: null
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialDashboardState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		dashboardFetched: (state, action) => {
      const { totalCount, entities, tgl_mulai, tgl_selesai } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
      state.tgl_mulai = tgl_mulai ? tgl_mulai : `${monthDate().tgl_mulai}`;
      state.tgl_selesai = tgl_selesai ? tgl_selesai : `${monthDate().tgl_selesai}`;
    },
		
    dashboardNewCustomerMahasiswa: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.new_customer_mahasiswa = entities;
    },
    
    dashboardNewCustomerResidential: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.new_customer_residential = entities;
    },
    
    dashboardTotalFab: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.total_fab = entities;
    },
    
    dashboardWaitingSurvey: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.waiting_survey = entities;
    },
    
    dashboardWaitingInstalasi: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.waiting_instalasi = entities;
    },
    
    dashboardReject: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.reject = entities;
    },
    
    dashboardAvgDaysSurvey: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.avg_days_survey = entities;
    },
    
    dashboardAvgDaysInstalasi: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.avg_days_instalasi = entities;
    },
    
    dashboardSales: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.sales = entities;
    },
    
    dashboardTeknikal: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.teknikal = entities;
    },
    
    dashboardTarget: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.target = entities.target;
    },
    
    dashboardAllKaryawan: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.all_karyawan = entities.all_karyawan;
    },
    
    dashboardAllKaryawanSales: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.all_karyawan_sales = entities;
    },
    
    dashboardAllKaryawanTeknikal: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.all_karyawan_teknikal = entities;
    },
    
    dashboardFabChart: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.fab_chart = entities;
    },
    
    dashboardSurveyChart: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.survey_chart = entities;
    },
    
    dashboardInstalasiChart: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.instalasi_chart = entities;
    },
    
    dashboardResidentialClusterChart: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.residential_cluster_chart = entities;
    },
    
    dashboardResidentialClusterFabChart: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.residential_cluster_fab_chart = entities;
    },

    dashboardCreated: (state, action) => {
      const { entities, tgl_mulai, tgl_selesai } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      //state.entities.dashboard.push(action.payload.dashboard);
      state.entities = entities;
      state.tgl_mulai = tgl_mulai;
      state.tgl_selesai = tgl_selesai;
    },

    dashboardReset: (state) => {
      state.tgl_mulai = null;
      state.tgl_selesai = null;
    },
    
    dashboardSet: (state, action) => {
      const { tgl_mulai, tgl_selesai } = action.payload;
      state.tgl_mulai = tgl_mulai;
      state.tgl_selesai = tgl_selesai;
    },
  },
});
