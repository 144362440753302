import { createSlice } from "@reduxjs/toolkit";

const initialPaketHargaState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  paketHargaForEdit: undefined,
  paketHargaForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const paketHargaSlice = createSlice({
  name: "paketHarga",
  initialState: initialPaketHargaState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		paketHargaFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
    paketAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    paketHargaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.paketHarga.push(action.payload.paketHarga);
    },
    paketHargaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.paketHarga.filter(el => el.paketHarga_id !== action.payload.id);
    },
    getPaketHarga: (state, action) => {
      state.actionsLoading = false;
      state.paketHargaForEdit = action.payload.paketHargaForEdit;
      state.error = null;
    },
    getExportPaketHarga: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.paketHargaForExport === undefined) {
        state.paketHargaForExport = [];
      }else{
        state.paketHargaForExport.push(action.payload.paketHargaForExport);
      }
      state.error = null;
    },
    // updateProduct
    paketHargaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.paketHarga = state.entities.paketHarga.map(entity => {
        if (entity.paketHarga_id === action.payload.paketHarga.paketHarga_id) {
          return action.payload.paketHarga;
        }
        return entity;
      });
    },
  },
});
