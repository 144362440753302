import { createSlice } from "@reduxjs/toolkit";

const initialMahasiswaState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  meta: {total : 0},
  links: null,
  mahasiswaForEdit: undefined,
  mahasiswaForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const mahasiswaSlice = createSlice({
  name: "mahasiswa",
  initialState: initialMahasiswaState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		mahasiswaFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		mahasiswaAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
		mahasiswaUniqueFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
		mahasiswaExportFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.mahasiswaForExport = entities;
      state.totalCount = entities.length;
    },
    mahasiswaCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.mahasiswa.push(action.payload.mahasiswa);
    },
    mahasiswaDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.mahasiswa.filter(el => el.mahasiswa_id !== action.payload.id);
    },
    getMahasiswa: (state, action) => {
      state.actionsLoading = false;
      state.mahasiswaForEdit = action.payload.mahasiswaForEdit;
      state.error = null;
    },
    getExportMahasiswa: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.mahasiswaForExport === undefined) {
        state.mahasiswaForExport = [];
      }else{
        state.mahasiswaForExport.push(action.payload.mahasiswaForExport);
      }
      state.error = null;
    },
    // updateProduct
    mahasiswaUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.mahasiswa = state.entities.mahasiswa.map(entity => {
        if (entity.mahasiswa_id === action.payload.mahasiswa.mahasiswa_id) {
          return action.payload.mahasiswa;
        }
        return entity;
      });
    },
  },
});
