import { createSlice } from "@reduxjs/toolkit";

const initialResellerState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  resellerForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const resellerSlice = createSlice({
  name: "reseller",
  initialState: initialResellerState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		resellerFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta ?? {total : 0};
      state.links = links;
    },
		resellerAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    resellerCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.reseller.push(action.payload.reseller);
    },
    resellerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.reseller.filter(el => el.reseller_id !== action.payload.id);
    },
    getReseller: (state, action) => {
      state.actionsLoading = false;
      state.resellerForEdit = action.payload.resellerForEdit;
      state.error = null;
    },
    // updateProduct
    resellerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.reseller = state.entities.reseller.map(entity => {
        if (entity.reseller_id === action.payload.reseller.reseller_id) {
          return action.payload.reseller;
        }
        return entity;
      });
    },
  },
});
