import { createSlice } from "@reduxjs/toolkit";

const initialAccountState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  entitiesTraffic: null,
  exportData: null,
  currency: null,
  lastError: null,
  accountForEdit: undefined,
  accountForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const accountSlice = createSlice({
  name: "account",
  initialState: initialAccountState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		accountFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		exportAccount: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
		exportFetchedAccount: (state, action) => {
      const { totalCount, export_data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = export_data;
      state.totalCount = totalCount;
    },
		trafficAccount: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesTraffic = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
    accountCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.account.push(action.payload.account);
    },
    accountDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.account.filter(el => el.account_id !== action.payload.id);
    },
    accountDisabled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.account.filter(el => el.account_id !== action.payload.id);
    },
    getAccount: (state, action) => {
      state.actionsLoading = false;
      state.accountForEdit = action.payload.accountForEdit;
      state.error = null;
    },
    getExportAccount: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.accountForExport === undefined) {
        state.accountForExport = [];
      }else{
        state.accountForExport.push(action.payload.accountForExport);
      }
      state.error = null;
    },
    // updateProduct
    accountUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.account = state.entities.account.map(entity => {
        if (entity.account_id === action.payload.account.account_id) {
          return action.payload.account;
        }
        return entity;
      });
    },
  },
});
