import { createSlice } from "@reduxjs/toolkit";

const initialDivisiState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  divisiForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};


export const divisiSlice = createSlice({
  name: "divisi",
  initialState: initialDivisiState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
      
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		divisiFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		divisiAllFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    divisiCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.divisi.push(action.payload.divisi);
    },
    divisiDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.divisi.filter(el => el.divisi_id !== action.payload.id);
    },
    getDivisi: (state, action) => {
      state.actionsLoading = false;
      state.divisiForEdit = action.payload.divisiForEdit;
      state.error = null;
    },
    // updateProduct
    divisiUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.divisi = state.entities.divisi.map(entity => {
        if (entity.divisi_id === action.payload.divisi.divisi_id) {
          return action.payload.divisi;
        }
        return entity;
      });
    },
  },
});
