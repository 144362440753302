/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */
import * as authredux from "../../src/app/modules/Auth/_redux/authRedux";
import { cekToken } from "../../src/app/modules/Auth/_redux/authCrud";
import { AuthPage, Logout } from "./modules/Auth";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import BasePage from "./BasePage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import {Layout} from "../_metronic/layout";
import React, { useEffect } from "react";

export function Routes() {
    const {isAuthorized, tokenStatus, token} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.authToken ? true : false,
            tokenStatus: auth.statusToken,
            token: auth.authToken,
        }),
        shallowEqual
    );
    
    useEffect(() => {
        if(token){
            cekToken(token)
            .then((data_cek) =>{
                authredux.actions.cekTokenAuth({statusToken : data_cek.status, isExpired : data_cek.data.isexpired});
            })
            .catch((error) => {
                authredux.actions.cekTokenAuth({statusToken : error.status, isExpired : true});
            });
        }
    }, [token]);

    return (
        <Switch>
            {!isAuthorized ? (
                <Route>
                    <AuthPage />
                </Route>
            ) :  
            !tokenStatus ? (
                <Redirect to="/logout"/>
            ) : 
            (<Redirect from="/auth" to="/"/>)}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>


            {!isAuthorized ? (
                <Redirect to="/logout"/>
            ) : 
            !tokenStatus ? (
                <Redirect to="/logout"/>
            ) : 
            (
                <Layout>
                    <BasePage/>
                </Layout>
            )}
        </Switch>
    );
}
