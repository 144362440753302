import { createSlice } from "@reduxjs/toolkit";

const initialLoadingState = {
  actionsLoading: false,
};

export const callTypes = {
  action: "action",
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState: initialLoadingState,
  reducers: {
	setLoadingAction: (state, action) => {
        const {status} = action.payload;
        state.actionsLoading = status;
    },
	getLoadingAction: (state, action) => {
        const {status} = action.payload;
        state.actionsLoading = status;
    },
  },
});
