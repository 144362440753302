import { createSlice } from "@reduxjs/toolkit";

const initialActiveState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  activeForEdit: undefined,
  activeForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const activeSlice = createSlice({
  name: "active",
  initialState: initialActiveState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		activeFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		activeExportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.activeForExport = entities;
      state.totalCount = totalCount;
    },
    activeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.active.push(action.payload.active);
    },
    activeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.active.filter(el => el.active_id !== action.payload.id);
    },
    getActive: (state, action) => {
      state.actionsLoading = false;
      state.activeForEdit = action.payload.activeForEdit;
      state.error = null;
    },
    getExportActive: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.activeForExport === undefined) {
        state.activeForExport = [];
      }else{
        state.activeForExport.push(action.payload.activeForExport);
      }
      state.error = null;
    },
    // updateProduct
    activeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.active = state.entities.active.map(entity => {
        if (entity.active_id === action.payload.active.active_id) {
          return action.payload.active;
        }
        return entity;
      });
    },
  },
});
