import { createSlice } from "@reduxjs/toolkit";

const initialPaymentTypeState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  paymentTypeForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const paymentTypeSlice = createSlice({
  name: "paymentType",
  initialState: initialPaymentTypeState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		paymentTypeFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		paymentTypeAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    paymentTypeCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.paymentType.push(action.payload.paymentType);
    },
    paymentTypeDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.paymentType.filter(el => el.payment_type_id !== action.payload.id);
    },
    getPaymentType: (state, action) => {
      state.actionsLoading = false;
      state.paymentTypeForEdit = action.payload.paymentTypeForEdit;
      state.error = null;
    },
    // updateProduct
    paymentTypeUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.paymentType = state.entities.paymentType.map(entity => {
        if (entity.payment_type_id === action.payload.paymentType.payment_type_id) {
          return action.payload.paymentType;
        }
        return entity;
      });
    },
  },
});
