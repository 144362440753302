import * as Yup from "yup";
import * as auth from "../_redux/authRedux";

import { FormattedMessage, injectIntl } from "react-intl";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getUserByToken, login, changePassword, cekToken } from "../_redux/authCrud";
import styled from "styled-components";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { useFormik } from "formik";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  nik: "",
  old_password: "",
  new_password: "",
  confirm_password: "",
};

const LoginWrapper = styled.div`
    position: absolute;
    top: 65%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: rgba(30, 30, 45, 0.8);
    color: #fff;
    padding: 1rem;
    width: 40vw;
    min-width: 300px;
    max-width: 400px;
    border-radius: 0.5rem;
    @media only screen and (min-width: 768px){
      padding: 3rem;
      top: 50%;
    }
`;

const InputLogin = styled.input`
  border: none;
  border-bottom: 1px solid #fff;
  font-weight: bold;
  border-radius: 0;
  &::placeholder {
    color: #fff;
    opacity: 1;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Logos = styled.div`
  position: absolute;
  top: 15%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 10vw;
  height: 20vh;
  min-width: 80px;
  @media only screen and (min-width: 1141px){
    width: 8vw;
    height: 20vh;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
`;

function ChangePassword(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    nik: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    old_password: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    new_password: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirm_password: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .oneOf([Yup.ref('new_password'), null], 'Passwords must match with new password'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  // const getInputClasses = (fieldname) => {
  //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
  //     return "is-invalid";
  //   }

  //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
  //     return "is-valid";
  //   }

  //   return "";
  // };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        //login
        changePassword(values.nik, values.old_password, values.new_password, values.confirm_password)
          .then(() => {
            login(values.nik, values.new_password)
              .then(({ data }) => {
                disableLoading();
                props.login(data);
                return data
              })
              .then((data) => {
                cekToken(data ? `Bearer ${data.token}` : null)
                  .catch(() => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_LOGIN",
                      })
                    );
                  });
              })
              .catch(() => {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN",
                  })
                );
              });
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <Wrapper>
      <Logos>
        <img
          src={`${toAbsoluteUrl("/media/logos/mynet.png")}`}
          alt="MYNET"
        />
      </Logos>
      <Title>
        <h4>Sistem Informasi Mynet Terpadu</h4>
      </Title>
      <LoginWrapper>
        <h1><FormattedMessage id="Change Password" /> </h1>
        <form className="form row" id="kt_login_signin_form" onSubmit={formik.handleSubmit}>
          <div className="form-group col-md-12 col-sm-12 col-xs-12">
            <InputLogin
              className="form-control h-auto text-white bg-white-o-5"
              type="text"
              placeholder="NIK"
              name="nik"
              autocomplete="off"
              {...formik.getFieldProps("nik")}
            />
            {formik.touched.nik && formik.errors.nik ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.nik}</div>
            </div>
          ) : null}
          </div>
          <div className="form-group col-md-12 col-sm-12 col-xs-12">
            <InputLogin
              className="form-control h-auto text-white bg-white-o-5"
              type="password"
              placeholder="Old Password"
              name="old_password"
              {...formik.getFieldProps("old_password")}
            />
            {formik.touched.old_password && formik.errors.old_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.old_password}</div>
            </div>
          ) : null}
          </div>
          <div className="form-group col-md-12 col-sm-12 col-xs-12">
            <InputLogin
              className="form-control h-auto text-white bg-white-o-5"
              type="password"
              placeholder="New Password"
              name="new_password"
              {...formik.getFieldProps("new_password")}
            />
            {formik.touched.new_password && formik.errors.new_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.new_password}</div>
            </div>
          ) : null}
          </div>
          <div className="form-group col-md-12 col-sm-12 col-xs-12">
            <InputLogin
              className="form-control h-auto text-white bg-white-o-5"
              type="password"
              placeholder="Confirm Password"
              name="confirm_password"
              {...formik.getFieldProps("confirm_password")}
            />
            {formik.touched.confirm_password && formik.errors.confirm_password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.confirm_password}</div>
            </div>
          ) : null}
          </div>
          
          <div className="form-group col-6">
            <button
              type="submit"
              id="kt_login_signin_submit"
              className="btn btn-success btn-block"
              disabled={formik.isSubmitting}
            >
              <span>Change</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
          <div className="form-group col-6">
            <Link to="/auth/login"
              id="kt_login_signup"
              className="btn btn-warning btn-block"
            >
              Login
            </Link>
          </div>
        </form>
      </LoginWrapper>
    </Wrapper>
  );
}

export default injectIntl(connect(null, auth.actions)(ChangePassword));
