import Moment from 'moment';
import { extendMoment } from 'moment-range';
 
const moment = extendMoment(Moment);

export const rangeDate = (startDate,endDate) => {
    //var name = f.name;
    //const start = moment(startDate, 'YYYY-MM-DD');
    //const end   = moment(endDate, 'YYYY-MM-DD');
    const dates = [moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')];
    const range = moment.range(dates);
    const originArrRange = Array.from(range.by('day'));
    const arrRange = originArrRange.map(x => x.format('YYYY-MM-DD'));
    
    return arrRange;
}

export const monthDate = () => {
    const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
    const endOfMonth   = moment().clone().endOf('month').format('YYYY-MM-DD');
    const startOfYear = moment().clone().startOf('year').format('YYYY-MM-DD');
    const endOfYear   = moment().clone().endOf('year').format('YYYY-MM-DD');
    return {
        tgl_mulai : startOfMonth,
        tgl_selesai : endOfMonth,
        tgl_mulai_tahun : startOfYear,
        tgl_selesai_tahun : endOfYear
    }
}