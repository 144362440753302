import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

import { NavLink } from "react-router-dom";
import React from "react";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router";

const AsideLinkMenu = ({data}) => {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
      return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "";
    };
    return (
        <li
          className={`menu-item ${getMenuItemActive(data.path, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={`${data.path}`}>
              { 
                data.icon ?
                (<span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl(data.icon)} />
                </span>) :
                <i className="menu-bullet menu-bullet-dot">
                    <span />
                </i>
              }
            <span className="menu-text">{data.text}</span>
          </NavLink>
        </li>
    )
}
export default AsideLinkMenu;