import { createSlice } from "@reduxjs/toolkit";

const initialQuotationState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  quotationForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const quotationSlice = createSlice({
  name: "quotation",
  initialState: initialQuotationState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		quotationFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    quotationCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.quotation.push(action.payload.quotation);
    },
    quotationDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.quotation.filter(el => el.quotation_id !== action.payload.id);
    },
    getQuotation: (state, action) => {
      state.actionsLoading = false;
      state.quotationForEdit = action.payload.quotationForEdit;
      state.error = null;
    },
    // updateProduct
    quotationUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.quotation = state.entities.quotation.map(entity => {
        if (entity.quotation_id === action.payload.quotation.quotation_id) {
          return action.payload.quotation;
        }
        return entity;
      });
    },
  },
});
