import { createSlice } from "@reduxjs/toolkit";

const initialPelangganState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  pelangganForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const pelangganSlice = createSlice({
  name: "pelanggan",
  initialState: initialPelangganState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		pelangganFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    pelangganCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.pelanggan.push(action.payload.pelanggan);
    },
    pelangganDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.pelanggan.filter(el => el.pelanggan_id !== action.payload.id);
    },
    getPelanggan: (state, action) => {
      state.actionsLoading = false;
      state.pelangganForEdit = action.payload.pelangganForEdit;
      state.error = null;
    },
    // updateProduct
    pelangganUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.pelanggan = state.entities.pelanggan.map(entity => {
        if (entity.pelanggan_id === action.payload.pelanggan.pelanggan_id) {
          return action.payload.pelanggan;
        }
        return entity;
      });
    },
  },
});
