// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import React from "react";
const alertColor = [
    {
        kasus : '[FAB]',
        color : 'primary'
    },
    {
        kasus : '[Survey]',
        color : 'info'
    },
    {
        kasus : '[SPK Instalasi]',
        color : 'warning'
    },
    {
        kasus : '[Laporan Instalasi]',
        color : 'success'
    },
    {
        kasus : '[Process Rejection]',
        color : 'dark'
    },
    {
        kasus : '[Rejection]',
        color : 'danger'
    },
];
export const NotifikasiList = ({name,data}) => {
    return (
        <div className="row d-flex justify-content-center my-3">
          <div className="col-12">
            {
                data.map(x => {
                    const color = alertColor.filter(y=>y.kasus === x.kasus);
                    return (
                        <div class={`alert alert-${color.length > 0 ? color[0].color : ""}`} role="alert">
                            <b>{`${x.kasus}`}</b> {`${x.keterangan}, ${x.kasus === '[FAB]' ? 'Sales' : 'PIC'} ${x.pic.name}`}
                        </div>
                    );
                })
            }
          </div>
        </div>
    );
}
