import { createSlice } from "@reduxjs/toolkit";

const initialJabatanState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  jabatanForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const jabatanSlice = createSlice({
  name: "jabatan",
  initialState: initialJabatanState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		jabatanFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		jabatanAllFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    jabatanCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.jabatan.push(action.payload.jabatan);
    },
    jabatanDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.jabatan.filter(el => el.jabatan_id !== action.payload.id);
    },
    getJabatan: (state, action) => {
      state.actionsLoading = false;
      state.jabatanForEdit = action.payload.jabatanForEdit;
      state.error = null;
    },
    // updateProduct
    jabatanUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.jabatan = state.entities.jabatan.map(entity => {
        if (entity.jabatan_id === action.payload.jabatan.jabatan_id) {
          return action.payload.jabatan;
        }
        return entity;
      });
    },
  },
});
