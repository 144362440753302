import * as requestFromServer from "./menuCrud";

import { callTypes, menuSlice } from "./menuSlice";

const { actions } = menuSlice;

export const fetchNewCustomerMahasiswa = (history, tgl_mulai=null, tgl_selesai=null) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNewCustomerMahasiswa(tgl_mulai,tgl_selesai)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.menuNewCustomerMahasiswa({ entities : data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find role";
      if(history){
        if(error.response.status === 401) {
          history.push("/logout");
        }
      }
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchNewCustomerResidential = (history, tgl_mulai=null, tgl_selesai=null) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNewCustomerResidential(tgl_mulai,tgl_selesai)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.menuNewCustomerResidential({ entities : data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find role";
      if(history){
        if(error.response.status === 401) {
          history.push("/logout");
        }
      }
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTotalFab = (history, tgl_mulai=null, tgl_selesai=null) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getTotalFab(tgl_mulai,tgl_selesai)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.menuTotalFab({ entities : data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find role";
      if(history){
        if(error.response.status === 401) {
          history.push("/logout");
        }
      }
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchWaitingSurvey = (history, tgl_mulai=null, tgl_selesai=null) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getWaitingSurvey(tgl_mulai,tgl_selesai)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.menuWaitingSurvey({ entities : data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find role";
      if(history){
        if(error.response.status === 401) {
          history.push("/logout");
        }
      }
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchWaitingInstalasi = (history, tgl_mulai=null, tgl_selesai=null) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getWaitingInstalasi(tgl_mulai,tgl_selesai)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.menuWaitingInstalasi({ entities : data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find role";
      if(history){
        if(error.response.status === 401) {
          history.push("/logout");
        }
      }
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchReject = (history, tgl_mulai=null, tgl_selesai=null) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getReject(tgl_mulai,tgl_selesai)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.menuReject({ entities : data }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find role";
      if(history){
        if(error.response.status === 401) {
          history.push("/logout");
        }
      }
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const resetMenu = () => (dispatch) => {
  dispatch(actions.menuReset());
};

export const setMenu = (tgl_mulai=null,tgl_selesai=null) => (dispatch) => {
  dispatch(actions.menuSet({tgl_mulai : tgl_mulai, tgl_selesai : tgl_selesai}));
};

// export const createMenu = (dataMenu) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));
//   return requestFromServer
//     .createMenu(dataMenu)
//     .then(response => {
//       const { data, msg } = response.data;
//       dispatch(actions.menuCreated({ entities : data ,tgl_mulai : dataMenu.tgl_mulai ,tgl_selesai : dataMenu.tgl_selesai  }));

//       if(msg === "SUCCESS"){
//         //toast.success(msg + " mengambil data menu");
//       }else{
//         //toast.error(msg + " mengambil data menu");
//       }
//     })
//     .catch(error => {
//       error.clientMessage = "Can't create customer";
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// }
