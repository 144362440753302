import { createSlice } from "@reduxjs/toolkit";

const initialOnlineState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  currency: null,
  lastError: null,
  onlineForEdit: undefined,
  onlineForExport: []
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const onlineSlice = createSlice({
  name: "online",
  initialState: initialOnlineState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		onlineFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
    onlineCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.online.push(action.payload.online);
    },
    onlineDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.online.filter(el => el.online_id !== action.payload.id);
    },
    onlineDisabled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.online.filter(el => el.online_id !== action.payload.id);
    },
    getOnline: (state, action) => {
      state.actionsLoading = false;
      state.onlineForEdit = action.payload.onlineForEdit;
      state.error = null;
    },
    getExportOnline: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.onlineForExport === undefined) {
        state.onlineForExport = [];
      }else{
        state.onlineForExport.push(action.payload.onlineForExport);
      }
      state.error = null;
    },
    // updateProduct
    onlineUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.online = state.entities.online.map(entity => {
        if (entity.online_id === action.payload.online.online_id) {
          return action.payload.online;
        }
        return entity;
      });
    },
  },
});
