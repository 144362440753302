import { createSlice } from "@reduxjs/toolkit";

const initialNotifikasiState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: undefined,
  lastError: null,
  notifikasiForEdit: undefined,
  tgl_mulai: null,
  tgl_selesai: null
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const notifikasiSlice = createSlice({
  name: "notifikasi",
  initialState: initialNotifikasiState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		notifikasiFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = state.entities === undefined ? entities : state.entities.concat(entities);
      state.totalCount = totalCount;
    },
		notifikasiFetchedNew: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = state.entities === undefined ? entities : entities.concat(state.entities);
      state.totalCount = totalCount;
    },
  },
});
