import { createSlice } from "@reduxjs/toolkit";

const initialMemberState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  entitiesTraffic: null,
  exportData: null,
  currency: null,
  lastError: null,
  memberForEdit: undefined,
  memberForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const memberSlice = createSlice({
  name: "member",
  initialState: initialMemberState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		memberFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		exportMember: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
		exportFetchedMember: (state, action) => {
      const { totalCount, export_data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.exportData = export_data;
      state.totalCount = totalCount;
    },
		trafficMember: (state, action) => {
      const { totalCount, entities, currency } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entitiesTraffic = entities;
      state.currency = currency;
      state.totalCount = totalCount;
    },
    memberCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.member.push(action.payload.member);
    },
    memberDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.member.filter(el => el.member_id !== action.payload.id);
    },
    memberDisabled: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.member.filter(el => el.member_id !== action.payload.id);
    },
    getMember: (state, action) => {
      state.actionsLoading = false;
      state.memberForEdit = action.payload.memberForEdit;
      state.error = null;
    },
    getExportMember: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.memberForExport === undefined) {
        state.memberForExport = [];
      }else{
        state.memberForExport.push(action.payload.memberForExport);
      }
      state.error = null;
    },
    // updateProduct
    memberUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.member = state.entities.member.map(entity => {
        if (entity.member_id === action.payload.member.member_id) {
          return action.payload.member;
        }
        return entity;
      });
    },
  },
});
