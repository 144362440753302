import { createSlice } from "@reduxjs/toolkit";

const initialKaryawanState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  tokenStatus: true,
  karyawanForEdit: undefined,
  karyawanForExport: [],
  picForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const karyawanSlice = createSlice({
  name: "karyawan",
  initialState: initialKaryawanState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		karyawanFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		karyawanAllFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    karyawanCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.karyawan.push(action.payload.karyawan);
    },
    karyawanDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.karyawan.filter(el => el.karyawan_id !== action.payload.id);
    },
    getKaryawan: (state, action) => {
      state.actionsLoading = false;
      state.karyawanForEdit = action.payload.karyawanForEdit;
      state.error = null;
    },
    getCekAuth: (state, action) => {
      state.tokenStatus = action.payload.tokenStatus === 401 ? false : true;
      state.error = null;
    },
    getExportKaryawan: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.karyawanForExport === undefined) {
        state.karyawanForExport = [];
      }else{
        state.karyawanForExport.push(action.payload.karyawanForExport);
      }
      state.error = null;
    },
    getExportPic: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.picForExport === undefined) {
        state.picForExport = [];
      }else{
        state.picForExport.push(action.payload.picForExport);
      }
      state.error = null;
    },
    // updateProduct
    karyawanUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.karyawan = state.entities.karyawan.map(entity => {
        if (entity.karyawan_id === action.payload.karyawan.karyawan_id) {
          return action.payload.karyawan;
        }
        return entity;
      });
    },
  },
});
