import { createSlice } from "@reduxjs/toolkit";

const initialFinancialState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  financialForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const financialSlice = createSlice({
  name: "financial",
  initialState: initialFinancialState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		financialFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    financialCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.financial.push(action.payload.financial);
    },
    financialDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.financial.filter(el => el.financial_id !== action.payload.id);
    },
    getFinancial: (state, action) => {
      state.actionsLoading = false;
      state.financialForEdit = action.payload.financialForEdit;
      state.error = null;
    },
    // updateProduct
    financialUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.financial = state.entities.financial.map(entity => {
        if (entity.financial_id === action.payload.financial.financial_id) {
          return action.payload.financial;
        }
        return entity;
      });
    },
  },
});
