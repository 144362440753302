import * as auth from "../app/modules/Auth/_redux/authRedux";

import {all} from "redux-saga/effects";
import {combineReducers} from "redux";
import {loadingSlice} from "../custom/_redux/loadingSlice";
import {divisiSlice} from "../app/modules/hrd/_redux/divisi/divisiSlice";
import {instalasiSlice} from "../app/modules/crm/_redux/instalasi/instalasiSlice";
import {activeSlice} from "../app/modules/crm/_redux/active/activeSlice";
import {nonactiveSlice} from "../app/modules/crm/_redux/nonactive/nonactiveSlice";
import {progressSlice} from "../app/modules/crm/_redux/progress/progressSlice";
import {upgradeSlice} from "../app/modules/crm/_redux/upgrade/upgradeSlice";
import {jabatanSlice} from "../app/modules/hrd/_redux/jabatan/jabatanSlice";
import {karyawanSlice} from "../app/modules/hrd/_redux/karyawan/karyawanSlice";
import {kostSlice} from "../app/modules/master/_redux/kost/kostSlice";
import {mahasiswaSlice} from "../app/modules/crm/_redux/mahasiswa/mahasiswaSlice";
import {pelangganSlice} from "../app/modules/crm-corporate/_redux/pelanggan/pelangganSlice";
import {financialSlice} from "../app/modules/crm-corporate/_redux/financial/financialSlice";
import {varfinancialSlice} from "../app/modules/crm-corporate/_redux/varfinancial/varfinancialSlice";
import {quotationSlice} from "../app/modules/crm-corporate/_redux/quotation/quotationSlice";
import {poSlice} from "../app/modules/crm-corporate/_redux/po/poSlice";
import {kontrakSlice} from "../app/modules/crm-corporate/_redux/kontrak/kontrakSlice";
import {baktifasiSlice} from "../app/modules/crm-corporate/_redux/baktifasi/baktifasiSlice";
import {bformterminateSlice} from "../app/modules/crm-corporate/_redux/bformterminate/bformterminateSlice";
import {bterminateSlice} from "../app/modules/crm-corporate/_redux/bterminate/bterminateSlice";
import {paketHargaSlice} from "../app/modules/master/_redux/paket_harga/paketHargaSlice";
import {paymentTypeSlice} from "../app/modules/master/_redux/payment_type/paymentTypeSlice";
import {bankSlice} from "../app/modules/master/_redux/bank/bankSlice";
import {resellerSlice} from "../app/modules/master/_redux/reseller/resellerSlice";
import {targetSlice} from "../app/modules/master/_redux/target/targetSlice";
import {clustermahasiswaSlice} from "../app/modules/master/_redux/clustermahasiswa/clustermahasiswaSlice";
import {clusterresidentialSlice} from "../app/modules/master/_redux/clusterresidential/clusterresidentialSlice";
import {requestSlice} from "../app/modules/crm/_redux/request/requestSlice";
import {residentialSlice} from "../app/modules/crm/_redux/residential/residentialSlice";
import {roleSlice} from "../app/modules/user/_redux/role/roleSlice";
import {surveySlice} from "../app/modules/crm/_redux/survey/surveySlice";
import {rejectSlice} from "../app/modules/crm/_redux/reject/rejectSlice";
import {dashboardSlice} from "../app/modules/dashboard/_redux/dashboardSlice";
import {menuSlice} from "../app/modules/dashboard/_redux/menuSlice";
import {notifikasiSlice} from "../app/modules/dashboard/_redux/notifikasiSlice";
import {exportcrmSlice} from "../app/modules/crm/_redux/exportcrm/exportcrmSlice";
import {serviceSlice} from "../app/modules/radius/_redux/service/serviceSlice";
import {nasSlice} from "../app/modules/radius/_redux/nas/nasSlice";
import {memberSlice} from "../app/modules/radius/user/_redux/member/memberSlice";
import {accountSlice} from "../app/modules/radius/user/_redux/account/accountSlice";
import {announcementSlice} from "../app/modules/radius/user/_redux/announcement/announcementSlice";
import {onlineSlice} from "../app/modules/radius/user/_redux/online/onlineSlice";
import {logSlice} from "../app/modules/radius/user/_redux/log/logSlice";
import {invoiceSlice} from "../app/modules/finance/_redux/invoice/invoiceSlice";
import {vendorSlice} from "../app/modules/crm-corporate/_redux/master/vendor/vendorSlice";
import {itemSlice} from "../app/modules/crm-corporate/_redux/master/item/itemSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  loading: loadingSlice.reducer,
  divisi: divisiSlice.reducer,
  jabatan: jabatanSlice.reducer,
  karyawan: karyawanSlice.reducer,
  role: roleSlice.reducer,
  kost: kostSlice.reducer,
  paketHarga: paketHargaSlice.reducer,
  target: targetSlice.reducer,
  clustermahasiswa: clustermahasiswaSlice.reducer,
  clusterresidential: clusterresidentialSlice.reducer,
  mahasiswa: mahasiswaSlice.reducer,
  pelanggan: pelangganSlice.reducer,
  financial: financialSlice.reducer,
  varfinancial: varfinancialSlice.reducer,
  quotation: quotationSlice.reducer,
  po: poSlice.reducer,
  kontrak: kontrakSlice.reducer,
  baktifasi: baktifasiSlice.reducer,
  bformterminate: bformterminateSlice.reducer,
  bterminate: bterminateSlice.reducer,
  residential: residentialSlice.reducer,
  request: requestSlice.reducer,
  survey: surveySlice.reducer,
  reject: rejectSlice.reducer,
  instalasi: instalasiSlice.reducer,
  progress: progressSlice.reducer,
  upgrade: upgradeSlice.reducer,
  dashboard: dashboardSlice.reducer,
  menu: menuSlice.reducer,
  notifikasi: notifikasiSlice.reducer,
  exportcrm: exportcrmSlice.reducer,
  service: serviceSlice.reducer,
  nas: nasSlice.reducer,
  member: memberSlice.reducer,
  account: accountSlice.reducer,
  announcement: announcementSlice.reducer,
  online: onlineSlice.reducer,
  log: logSlice.reducer,
  invoice: invoiceSlice.reducer,
  paymentType: paymentTypeSlice.reducer,
  bank: bankSlice.reducer,
  reseller: resellerSlice.reducer,
  active: activeSlice.reducer,
  nonactive: nonactiveSlice.reducer,
  vendor: vendorSlice.reducer,
  item: itemSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
