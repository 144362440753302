import { createSlice } from "@reduxjs/toolkit";

const initialNonactiveState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  nonactiveForEdit: undefined,
  nonactiveForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const nonactiveSlice = createSlice({
  name: "nonactive",
  initialState: initialNonactiveState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		nonactiveFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		nonactiveExportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.nonactiveForExport = entities;
      state.totalCount = totalCount;
    },
    nonactiveCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.nonactive.push(action.payload.nonactive);
    },
    nonactiveDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.nonactive.filter(el => el.nonactive_id !== action.payload.id);
    },
    getNonactive: (state, action) => {
      state.actionsLoading = false;
      state.nonactiveForEdit = action.payload.nonactiveForEdit;
      state.error = null;
    },
    getExportNonactive: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.nonactiveForExport === undefined) {
        state.nonactiveForExport = [];
      }else{
        state.nonactiveForExport.push(action.payload.nonactiveForExport);
      }
      state.error = null;
    },
    // updateProduct
    nonactiveUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.nonactive = state.entities.nonactive.map(entity => {
        if (entity.nonactive_id === action.payload.nonactive.nonactive_id) {
          return action.payload.nonactive;
        }
        return entity;
      });
    },
  },
});
