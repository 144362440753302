import { createSlice } from "@reduxjs/toolkit";

const initialVendorState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  totalPerPage: 10,
  totalPerPageCount: 0,
  pageNumber: 1,
  entities: null,
  lastError: null,
  vendorForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const vendorSlice = createSlice({
  name: "vendor",
  initialState: initialVendorState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		vendorFetched: (state, action) => {
      const { meta, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = meta ? meta.total : entities.length;
      state.totalPerPage = meta ? meta.per_page : 10;
      state.totalPerPageCount = meta ? meta.to : 0;
      state.pageNumber = meta ? meta.current_page : 1;
    },
    vendorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.vendor.push(action.payload.vendor);
    },
    vendorDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.vendor.filter(el => el.vendor_id !== action.payload.id);
    },
    getVendor: (state, action) => {
      state.actionsLoading = false;
      state.vendorForEdit = action.payload.vendorForEdit;
      state.error = null;
    },
    // updateProduct
    vendorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.vendor = state.entities.vendor.map(entity => {
        if (entity.vendor_id === action.payload.vendor.vendor_id) {
          return action.payload.vendor;
        }
        return entity;
      });
    },
  },
});
