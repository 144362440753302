import { createSlice } from "@reduxjs/toolkit";

const initialRejectState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  rejectForEdit: undefined,
  rejectForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const rejectSlice = createSlice({
  name: "reject",
  initialState: initialRejectState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		rejectFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		rejectExportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.rejectForExport = entities;
      state.totalCount = totalCount;
    },
    rejectCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.reject.push(action.payload.reject);
    },
    rejectDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.reject.filter(el => el.reject_id !== action.payload.id);
    },
    getReject: (state, action) => {
      state.actionsLoading = false;
      state.rejectForEdit = action.payload.rejectForEdit;
      state.error = null;
    },
    // updateProduct
    rejectUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.reject = state.entities.reject.map(entity => {
        if (entity.reject_id === action.payload.reject.reject_id) {
          return action.payload.reject;
        }
        return entity;
      });
    },
  },
});
