import { createSlice } from "@reduxjs/toolkit";

const initialBterminateState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  bterminateForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const bterminateSlice = createSlice({
  name: "bterminate",
  initialState: initialBterminateState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		bterminateFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    bterminateCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.bterminate.push(action.payload.bterminate);
    },
    bterminateDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.bterminate.filter(el => el.bterminate_id !== action.payload.id);
    },
    getBterminate: (state, action) => {
      state.actionsLoading = false;
      state.bterminateForEdit = action.payload.bterminateForEdit;
      state.error = null;
    },
    // updateProduct
    bterminateUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.bterminate = state.entities.bterminate.map(entity => {
        if (entity.bterminate_id === action.payload.bterminate.bterminate_id) {
          return action.payload.bterminate;
        }
        return entity;
      });
    },
  },
});
