import { createSlice } from "@reduxjs/toolkit";

const initialInvoiceState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  totalPrice: 0,
  totalCountReseller: 0,
  totalPriceReseller: 0,
  totalFeeReseller: 0,
  generateNum: "",
  entities: null,
  meta: {total : 0},
  links: null,
  currency: "IDR",
  total_price: null,
  lastError: null,
  invoiceForEdit: undefined,
  invoiceForExport: [],
  invoiceForExportSummary: [],
  invoiceForGenerateMitra: [],
  invoiceForGenerateMitraReseller: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: initialInvoiceState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		invoiceFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
    getAggregationInvoice: (state, action) => {
      const { entity } = action.payload;
      state.actionsLoading = false;
      state.listLoading = false;
      state.totalPrice = entity.total_price;
      state.error = null;
    },
    invoiceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.invoice.push(action.payload.invoice);
    },
    invoiceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.invoice.filter(el => el.invoice_id !== action.payload.id);
    },
    getInvoice: (state, action) => {
      state.actionsLoading = false;
      state.invoiceForEdit = action.payload.invoiceForEdit;
      state.error = null;
    },
    getExportInvoice: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.invoiceForExport === undefined) {
        state.invoiceForExport = [];
      }else{
        state.invoiceForExport.push(action.payload.invoiceForExport);
      }
      state.error = null;
    },
    // updateProduct
    invoiceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.invoice = state.entities.invoice.map(entity => {
        if (entity.invoice_id === action.payload.invoice.invoice_id) {
          return action.payload.invoice;
        }
        return entity;
      });
    },
    exportFetchedInvoice: (state, action) => {
      const { totalCount, export_data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.export_data = export_data;
      state.totalCount = totalCount;
    },
    exportFetchedInvoiceSummary: (state, action) => {
      const { totalCount, export_data } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.invoiceForExportSummary = export_data;
      state.totalCount = totalCount;
    },
    exportInvoiceGenerateMitra: (state, action) => {
      const { 
        totalCount, 
        export_data, 
        total_price, 
        totalCountReseller, 
        export_data_reseller, 
        total_price_reseller, 
        total_fee_reseller, 
        generatenum 
      } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.invoiceForGenerateMitra = export_data;
      state.totalCount = totalCount;
      state.totalPrice = total_price;
      state.invoiceForGenerateMitraReseller = export_data_reseller;
      state.totalCountReseller = totalCountReseller;
      state.totalPriceReseller = total_price_reseller;
      state.totalFeeReseller = total_fee_reseller;
      state.generateNum = generatenum;
    },
  },
});
