export const arrayRange = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step)).fill(start).map((x, y) => x + y * step)

const formatSource = (x) =>{
  const price_amount = x.fields ? (x.fields.price_amount ? x.fields.price_amount[0] : 0) : 0;
  x._source.price_amount = price_amount;
  return x._source;
}

export const getSource = (data,aggregations=false) => 
  aggregations ? data : data.map(x => formatSource(x))
// export const getSource = (data,aggregations=false) => 
//   aggregations ? data : data.map(x => x._source)