import { createSlice } from "@reduxjs/toolkit";

const initialServiceState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  currency: null,
  lastError: null,
  serviceForEdit: undefined,
  serviceForExport: []
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const serviceSlice = createSlice({
  name: "service",
  initialState: initialServiceState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		serviceFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		serviceAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    serviceCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.service.push(action.payload.service);
    },
    serviceDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.service.filter(el => el.service_id !== action.payload.id);
    },
    getService: (state, action) => {
      state.actionsLoading = false;
      state.serviceForEdit = action.payload.serviceForEdit;
      state.error = null;
    },
    getExportService: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.serviceForExport === undefined) {
        state.serviceForExport = [];
      }else{
        state.serviceForExport.push(action.payload.serviceForExport);
      }
      state.error = null;
    },
    // updateProduct
    serviceUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.service = state.entities.service.map(entity => {
        if (entity.service_id === action.payload.service.service_id) {
          return action.payload.service;
        }
        return entity;
      });
    },
  },
});
