import { createSlice } from "@reduxjs/toolkit";

const initialBaktifasiState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  baktifasiForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const baktifasiSlice = createSlice({
  name: "baktifasi",
  initialState: initialBaktifasiState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		baktifasiFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    baktifasiCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.baktifasi.push(action.payload.baktifasi);
    },
    baktifasiDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.baktifasi.filter(el => el.baktifasi_id !== action.payload.id);
    },
    getBaktifasi: (state, action) => {
      state.actionsLoading = false;
      state.baktifasiForEdit = action.payload.baktifasiForEdit;
      state.error = null;
    },
    // updateProduct
    baktifasiUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.baktifasi = state.entities.baktifasi.map(entity => {
        if (entity.baktifasi_id === action.payload.baktifasi.baktifasi_id) {
          return action.payload.baktifasi;
        }
        return entity;
      });
    },
  },
});
